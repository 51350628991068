@mixin active-dot {
  content: '';
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: var(--blue);
}

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin white-box {
  padding: 80px;
  background: var(--white);
  border-radius: 16px;
  width: 100%;
  max-width: 546px;
}

@mixin custom-scroll {
  scrollbar-width: thin;
  scrollbar-color: var(--grey) var(--grey20);

  &::-webkit-scrollbar {
    width: 2px;
    background: var(--grey20);
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--grey);
    border-radius: 2px;
  }
}

@mixin remove-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}

@mixin full-height-for-inner-scroll {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@mixin gradient {
  content: '';
  height: 35px;
  width: calc(100% - 8px); // width of custom scrollbar: ~8px (depends on MF, GC)
  position: absolute;
  z-index: 10;
}
