@use "colors";

:root {
  --black: #{colors.$black};
  --dark-grey: #{colors.$dark-grey};
  --grey: #{colors.$grey};
  --semi-grey: #{colors.$semi-grey};
  --light-grey: #{colors.$light-grey};
  --white: #{colors.$white};

  --blue: #{colors.$blue};
  --dark-blue: #{colors.$dark-blue};
  --facebook-blue: #{colors.$facebook-blue};
  --light-blue: #{colors.$light-blue};
  --dark-light-blue: #{colors.$dark-light-blue};

  --red: #{colors.$red};
  --light-red: #{colors.$light-red};
  --green: #{colors.$green};
  --dark-green: #{colors.$dark-green};
  --light-green: #{colors.$light-green};

  --orange: #{colors.$orange};
  --light-orange: #{colors.$light-orange};

  --dark-light-grey: #{colors.$dark-light-grey};
  --grey20: #{colors.$grey20};
  --white50: #{colors.$white50};
  --white90: #{colors.$white90};
  --card-shadow: #{colors.$card-shadow};
  --player-gradient: linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 0.05) 100%);

  --fading-gradient-left: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 1) 100%);
  --fading-gradient-right: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 1) 100%);

  --default-background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
}

:root[theme='dark'] {
  --black: #{colors.$white};
  --white: #{colors.$black};
  --dark-grey: #{colors.$grey};
  --grey: #666870; //TODO: should switch with dark-grey, dark grey is too dark
  --light-grey: #{colors.$grey20};
  --red: #ff4545;

  --white50: #{colors.$black50};
  --white90: #{colors.$black80};
  --card-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
  --player-gradient: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);

  --fading-gradient-left: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #{colors.$black} 100%);
  --fading-gradient-right: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #{colors.$black} 100%);
}
