$card-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
$handcash-gradient: linear-gradient(269.21deg, rgba(77, 177, 227, 0.9) 0%, rgba(57, 202, 127, 0.9) 100%);
$black: #1d1f26;
$dark-grey: #4c4e54;
$dark-light-grey: #6e7179;
$grey: #c1c2c5;
$light-grey: #f2f2f2;
$white: #ffffff;
$semi-grey: #94979e;

$blue: #009fe3;
$dark-blue: #00709f;
$facebook-blue: #1977f3;
$light-blue: rgba(0, 159, 227, 0.15);
$light-blue-no-transparent: rgb(217 241 251);
$dark-light-blue: rgba(0, 159, 227, 0.3);

$red: #e30000;
$light-red: #fbd9d9;
$light-red-transparent: rgba(227, 0, 0, 0.15);
$dark-light-red: rgba(227, 0, 0, 0.3);
$green: #53d769;
$light-green: #e5f9e9;
$light-green-transparent: rgba(83, 215, 105, 0.15);
$dark-green: #269038;

$orange: #f2994a;
$light-orange: rgba(242, 153, 74, 0.15);

$grey20: rgba(193, 194, 197, 0.2);
$white50: rgba(255, 255, 255, 0.5);
$white90: rgba(255, 255, 255, 0.9);

$black80: rgba(29, 31, 38, 0.8);
$black50: rgba(29, 31, 38, 0.5);
