@use "./styles/colors";

@import './styles/themes';
@import './styles/spacers';
@import './styles/fonts';
@import 'mixins';

@import '~@ng-select/ng-select/themes/default.theme.css';
@import '~@angular/cdk/overlay-prebuilt.css';

@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';

body {
  margin: 0;
  background-color: var(--white);
  color: var(--black);
  font-family: 'Museo Sans';
  overflow-y: scroll;

  &.no-scroll {
    overflow: hidden;
  }
}

* {
  box-sizing: border-box;
}

/////////////////////////////////
/////// HEADINGS AND TEXT ///////
/////////////////////////////////
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  font-weight: 300;

  &.gradient-bronze {
    background-image: linear-gradient(#ddb788 30%, #f1cb9e 60%, #af895c 80%);
    background-clip: text;
    color: transparent;
  }
  &.gradient-silver {
    background-image: linear-gradient(#cacfd5 30%, #c9cbde 60%, #abb1c0 80%);
    background-clip: text;
    color: transparent;
  }
  &.gradient-gold {
    background-image: linear-gradient(#cfb72c 30%, #ffed1d 60%, #ddbb34 80%);
    background-clip: text;
    color: transparent;
  }
}

h1 {
  font-size: 40px;
  line-height: 48px;
}

h2 {
  font-size: 32px;
  line-height: 38px;
}

h3 {
  font-size: 28px;
  line-height: 34px;
}

h4 {
  font-weight: 600; // not defined in figma; in register pages it is 600, so maybe it is 600?
  font-size: 24px;
  line-height: 28px;
}

h5 {
  font-size: 20px;
  line-height: 24px;
}

h6 {
  font-weight: 500; // TODO: should be 600, but we only have 100, 300, 500, 700
  font-size: 16px;
  line-height: 19px;
}

p,
span {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;

  &.small {
    font-size: 12px;
    line-height: 16px;
  }

  &.medium {
    font-size: 14px;
    line-height: 18px;
  }

  &.bold {
    font-weight: 600;
    line-height: 20px;
  }

  &.underline {
    text-decoration: underline;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  &.white {
    color: var(--white);
  }

  &.light {
    color: var(--semi-grey);
  }

  &.blue {
    color: var(--blue);
  }

  &.green {
    color: var(--green);
  }

  &.orange {
    color: var(--orange);
  }

  &.red {
    color: var(--red);
  }

  &.dark-grey {
    color: var(--dark-grey);
  }

  &.grey {
    color: var(--grey);
  }
}

.mw-0 {
  min-width: 0;
}

.caption {
  font-size: 12px;
  line-height: 14px;
  color: var(--grey);
}

.static-pages-heading {
  font-weight: 300;
  font-size: 56px;
  line-height: 67px;
}

.static-pages-heading-mobile {
  font-weight: 300;
  font-size: 40px;
  line-height: 48px;
}

.static-pages-subtitle {
  font-weight: 300;
  font-size: 32px;
  line-height: 38px;
}

.static-pages-subtitle-mobile {
  font-weight: 300;
  font-size: 28px;
  line-height: 33px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-nowrap {
  white-space: nowrap;
}

.w-100 {
  width: 100%;
}

.break-word {
  word-break: break-word;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  // color: inherit;
  color: var(--black);
  -webkit-tap-highlight-color: transparent;
}

/* FONT AWESOME */
fa-icon {
  &.light {
    color: var(--grey);
  }
  &.green {
    color: var(--green);
  }

  &.orange {
    color: var(--orange);
  }

  &.grey {
    color: var(--grey);
  }

  &.dark-grey {
    color: var(--dark-grey);
  }

  &.red {
    color: var(--red);
  }

  &.blue {
    color: var(--blue);
  }
}

/* BUTTONS */
button {
  // display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 24px;
  border-radius: 20px;
  border: none;
  outline: none;
  background-color: var(--blue);
  color: colors.$white;
  font-size: 16px;
  font-family: 'Museo Sans';
  font-weight: 500; // TODO: should be 600, but we only have 100, 300, 500, 700
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  &:active {
    background-color: var(--dark-blue);
  }

  &.big {
    padding: 10px 24px;
  }

  &.secondary {
    background-color: var(--light-blue);
    color: var(--blue);

    &:active:enabled {
      background-color: var(--dark-light-blue);
    }

    &.loader {
      &::after {
        border-top-color: var(--blue);
      }
    }
  }

  &.light {
    background-color: var(--white50);
    color: var(--white);

    &.loader {
      &::after {
        border-top-color: var(--white);
      }
    }

    &:focus {
      outline: solid 1px var(--blue);
    }
  }

  &.red {
    background-color: colors.$light-red;
    color: colors.$red;

    &:active:enabled {
      background-color: colors.$dark-light-red;
    }

    &.loader {
      &::after {
        border-top-color: var(--red);
      }
    }
  }

  &.white {
    background-color: colors.$white;
    color: colors.$blue;

    &:active:enabled {
      background-color: var(--white50);
    }
  }

  &.handcash {
    background: colors.$handcash-gradient;
    color: colors.$white;

    &:active:enabled {
      background-color: rgba(57, 202, 127, 0.9);
    }

    &.loader {
      &::after {
        border-top-color: colors.$white;
      }
    }
  }

  &:disabled,
  &[disabled] {
    background-color: var(--grey20);
    color: var(--grey);
    cursor: auto;

    &:hover {
      cursor: auto;
    }

    &:active {
      background-color: none;
    }

    &:focus {
      outline: solid 1px var(--grey);
    }

    fa-icon {
      cursor: auto;
      color: var(--grey);
    }
  }

  &.icon-button {
    padding: 0;
    background-color: transparent;
    color: inherit;

    fa-icon {
      margin: 0;
    }

    &:focus {
      outline: solid 1px transparent;
    }

    &:active {
      background-color: unset;
    }
  }

  &.loader {
    &::after {
      border-top-color: colors.$white;
    }
  }

  &.no-style {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    text-align: left;
    border-radius: 0;

    &:focus {
      outline: solid 1px transparent;
    }

    &.loader::before {
      background-color: var(--white);
    }

    &.loader::after {
      border-top-color: var(--blue);
    }
  }
}

//Simple loader
.loader {
  position: relative;
  color: transparent !important;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  &.white::before {
    background-color: var(--white);
  }

  &.grey::after {
    border-top-color: var(--grey);
  }
}

.loader::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.loader::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: var(--blue);
  border-radius: 50%;
  animation: loader-spinner 1s ease infinite;
}

@keyframes loader-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

/* INPUTS */
input[type='text'],
input[type='number'],
input[type='search'],
input[type='email'],
input[type='password'],
textarea {
  height: 40px;
  border: 1px solid transparent;
  border-radius: 16px;
  background: var(--grey20);
  padding: 8px 16px;
  outline: 1px solid transparent;
  font-family: 'Museo Sans';
  color: var(--black);
  font-size: 16px;
  line-height: 24px;
  transition: border 0.3s linear;

  &:focus {
    border: 1px solid var(--blue);
  }

  &::placeholder {
    color: var(--grey);
    opacity: 1; /* Firefox */
  }

  &:disabled {
    transition: color 0.3s linear;
    color: var(--grey);
  }
}
input[type='number'],
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  margin: 0;
}

textarea {
  height: unset;
  resize: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  //TODO show scrollbar?
}
label {
  cursor: pointer;
}

/* START - custom checkbox */
input[type='checkbox'] {
  height: 14px;
  width: 14px;
  margin: 0;
  padding: 0;
  opacity: 1;
  appearance: none;
  border: 1px solid var(--grey);
  border-radius: 3px;
  background: var(--white);
  position: relative;
  margin-right: 10px;
  cursor: pointer;
}

input[type='checkbox']:checked {
  border: 2px solid var(--blue);
  background: var(--blue);
}

input[type='checkbox']:checked:before,
input[type='checkbox']:checked:after {
  content: '';
  position: absolute;
  height: 2px;
  background: var(--white);
}

input[type='checkbox']:checked:before {
  width: 5px;
  top: 5px;
  left: 0px;
  transform: rotate(44deg);
}

input[type='checkbox']:checked:after {
  width: 9px;
  top: 4px;
  left: 2px;
  transform: rotate(-50deg);
}

input[type='checkbox']:focus {
  outline: none;
}
/* END - custom checkbox */

/* START - custom radio */
input[type='radio'] {
  cursor: pointer;
  height: 16px;
  width: 16px;
  margin: 0;
  padding: 0;
  opacity: 1;
  appearance: none;
  border: 1px solid var(--grey);
  border-radius: 50%;
  background: var(--white);
  position: relative;
}

input[type='radio']:checked {
  border: 2px solid var(--blue);

  &:disabled {
    border: 2px solid var(--grey);
  }
}

input[type='radio']:checked:after {
  content: '';
  position: absolute;
  height: 6px;
  background: var(--blue);
  width: 6px;
  top: 3px;
  left: 3px;
  border-radius: 50%;
}

input[type='radio']:checked:disabled:after {
  background: var(--grey);
}

input[type='radio']:focus {
  outline: none;
}

input[type='range'] {
  -webkit-appearance: none;
  height: 4px;
  background: var(--grey20);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 4px;

  &:disabled::-webkit-slider-thumb {
    cursor: auto;
  }

  &:disabled::-moz-range-thumb {
    cursor: auto;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 12px;
    height: 12px;
    background: colors.$white;
    border-radius: 50%;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 12px;
    height: 12px;
    background: colors.$white;
    border-radius: 50%;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }
}

/* CUSTOM TOGGLE SWITCH - similar to green IOS one */
.switch {
  display: inline-block;
  height: 25px;
  position: relative;
  width: 40px;

  input {
    display: none;

    &:checked {
      + {
        .slider {
          background-color: var(--green);
          &:before {
            transform: translateX(14px);
          }
        }
      }
    }
  }

  .slider {
    height: 25px;
    width: 40px;
    background-color: var(--grey);
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;
    border-radius: 34px;
    &:before {
      border-radius: 50%;
      background-color: colors.$white;
      bottom: 3px;
      content: '';
      height: 19px;
      left: 3px;
      position: absolute;
      transition: 0.4s;
      width: 19px;
    }
  }
}

/* NG-SELECT - dropdown select */
@mixin dropdown-arrow($arrow-color: var(--black)) {
  border: solid $arrow-color;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(45deg);
}

.ng-select.custom {
  width: 100%;
  border: 1px solid transparent;
  border-radius: 16px;

  &.error {
    .ng-select-container {
      border: 1px solid var(--red);
    }
  }

  .ng-select-container {
    background: var(--grey20);
    border: 1px solid transparent;
    border-radius: 16px !important;
    height: 40px;
    box-shadow: none;

    .ng-placeholder {
      color: var(--grey);
      margin-left: 6px;
    }
  }

  input {
    height: inherit;
    border-radius: 0;
    transition: border 0.3s linear;
    &:focus {
      border: 1px solid transparent;
    }
  }

  .ng-value {
    color: var(--black);
    margin-top: 2px;
    margin-left: 6px;

    .ng-value-label {
      padding-right: 3px;
    }
  }

  &.ng-select-focused {
    transition: border 0.3s linear;
    border: 1px solid var(--blue);
    border-radius: 16px;

    &:not(.ng-select-opened) > .ng-select-container {
      border-color: transparent;
      box-shadow: none;
    }
  }

  &.ng-select-single .ng-select-container .ng-value-container .ng-input {
    top: 8px;
    margin-left: 6px;
  }

  .ng-arrow {
    @include dropdown-arrow;
    transition: transform 0.24s ease-in;
    transform: rotate(45deg);
  }

  .ng-arrow-wrapper {
    margin-right: 12px;
    margin-top: -4px;
  }

  &.ng-select-opened {
    .ng-arrow {
      @include dropdown-arrow;
      transition: transform 0.24s ease-out;

      transform: rotate(-135deg) translateY(-5px) translateX(-5px);
    }

    // .ng-arrow-wrapper {
    //   padding-top: 9px;
    // }

    &.ng-select-bottom > .ng-select-container {
      border-bottom-right-radius: 16px;
      border-bottom-left-radius: 16px;
    }
  }

  .ng-has-value {
    .ng-arrow-wrapper {
      display: none;
    }
  }
  .ng-clear-wrapper {
    margin-right: 15px;
    &:hover {
      .ng-clear {
        color: var(--grey);
      }
    }
  }

  &.ng-select-disabled {
    .ng-arrow {
      color: colors.$red;
    }
  }

  ng-dropdown-panel {
    background-color: var(--white90);
    backdrop-filter: blur(10px); //TODO should be 60px
    margin-top: 8px;
    border-radius: 16px;
    border: 1px solid transparent;
    box-shadow: var(--card-shadow);

    .ng-dropdown-panel-items {
      border-radius: 8px;
      margin: 16px 16px 16px 6px;

      .ng-option {
        color: var(--black);
        background-color: transparent;
        &.ng-option-selected {
          background-color: transparent;
          &.ng-option-marked {
            color: var(--black);
            background-color: transparent;
          }
        }

        &.ng-option-marked {
          background-color: var(--light-grey);
          border-radius: 16px;
          margin-right: 8px;
        }
      }
    }
    &.ng-select-bottom {
      .ng-dropdown-panel-items .ng-option:last-child {
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
      }
    }

    /* width */
    ::-webkit-scrollbar {
      width: 2px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px var(--light-grey);
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: var(--grey);
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: var(--dark-grey);
    }
  }
}

.ng-select.non-searchable .ng-has-value .ng-arrow-wrapper {
  display: block;
}

/* INPUT FIELD WRAPPER */
.input-field-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  > label:first-child {
    transition: color 0.3s linear;
    margin-left: 16px;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: var(--black);
  }

  &.disabled {
    label,
    .note,
    div.error {
      color: var(--grey);
    }

    .ng-select.custom .ng-arrow {
      @include dropdown-arrow($arrow-color: var(--grey));
    }

    input {
      border: transparent;
    }
  }

  .note {
    transition: color 0.3s linear;
    //TODO: maybe remove all .input-field-wrapper mt-3 and add padding-bottom: 16px; to .input-field-wrapper
    margin-left: 16px;
    margin-bottom: -12px;
    font-size: 10px;
    line-height: 12px;
  }

  div.error {
    color: var(--red);
  }
  input,
  textarea {
    &.error {
      border: 1px solid var(--red);
    }
  }

  .prefix {
    position: absolute;
    top: 23px;
    left: 17px;
    color: var(--grey);
    pointer-events: none;
  }
}

form.disabled-form {
  .input-field-wrapper {
    label,
    .note,
    div.error {
      color: var(--grey);
    }

    .ng-select.custom {
      .ng-arrow {
        @include dropdown-arrow($arrow-color: var(--grey));
      }
      .ng-value-label {
        color: var(--grey);
      }
    }

    input[type='text'] {
      transition: color 0.3s linear;
      color: var(--grey);
    }
  }
}

/* CDK overlay */
.cdk-global-scrollblock {
  body {
    overflow-y: unset;
  }
}

.cdk-overlay-container {
  z-index: 100001;
  /*
  ng-dropdown popup is on 1000
  header and player should be on 10000
  */

  .cdk-overlay-dark-backdrop {
    background: rgba(16, 16, 16, 0.8);
  }
}

.cdk-global-scrollblock {
  overscroll-behavior-y: contain;
}

/* POPOVER */
.popover-wrapper {
  background-color: var(--white90);
  backdrop-filter: blur(10px); // TODO should be 60px, but is completly white to me
  border: 1px solid var(--white);
  border-radius: 16px;
  box-shadow: var(--card-shadow);

  .link {
    cursor: pointer;
    width: 100%;
    margin: 4px 0px;
    padding: 4px 16px;

    &:hover {
      background-color: var(--grey20);
      border-radius: 16px;
    }

    span {
      margin-right: 12px;
    }
  }

  &.mobile {
    border-radius: 16px 16px 0 0;
    width: 100vw;

    .link {
      flex-direction: row-reverse !important;
      padding: 4px 0px;

      span {
        margin-left: 20px;
      }
    }
  }
}

//ONFIDO overrides
.onfido-sdk-ui-Modal-inner {
  width: unset !important;
  max-width: unset !important;

  border-radius: 16px !important;

  button {
    &:active {
      background-color: unset;
    }
  }

  input[type='text'],
  input[type='search'],
  input[type='email'],
  input[type='password'],
  textarea {
    color: colors.$black;
    border: 1px solid rgba(var(--ods-color-border-input));
    border-radius: 0.25em;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 0;
    width: 100%;
  }
}

@media (max-width: 479px) {
  .onfido-sdk-ui-Modal-inner {
    position: relative !important;
    height: 37.5em !important;

    border-radius: 16px !important;
  }
}

// Horizontal slider - angular virtual scroll overrides
.cdk-virtual-scroll-wrapper {
  position: relative;
  .viewport {
    scroll-behavior: smooth;
    @include remove-scrollbar;
    // border: 1px solid black;
  }
}

.viewport .cdk-virtual-scroll-content-wrapper {
  display: flex;
  flex-direction: row;
}

//Reponsive table
table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  border-spacing: 16px;

  th {
    text-transform: uppercase;
  }

  tr {
    position: relative;
    cursor: pointer;
  }

  td {
    padding-top: 6px;
    padding-bottom: 6px;
    vertical-align: middle;
  }

  tbody tr:hover:after {
    background-color: var(--grey20);

    border-radius: 16px;

    content: '';
    position: absolute;
    left: -8px;
    top: 0px;
    z-index: -1;

    width: calc(100% + 16px);
    height: calc(100%);
  }
}

.mobile-table {
  border: 0;

  thead {
    display: none;
  }

  tr {
    display: block !important;
    margin-bottom: 16px;
    box-shadow: var(--card-shadow);
    padding: 8px;
    border-radius: 16px;
  }

  td {
    border-bottom: 1px solid var(--light-grey);
    display: block !important;
    font-size: 0.8em;
    text-align: right;

    &::before {
      /*
        * aria-label has no advantage, it won't be read inside a
        content: attr(aria-label);
        */
      content: attr(data-label);
      float: left;
      font-weight: 600;
    }

    &:last-child {
      border-bottom: 0;
    }
  }
}

.cover-image {
  width: 44px;
  height: 44px;
  border-radius: 8px;
  margin-left: auto;

  &.no-img {
    background-color: var(--semi-grey);
  }
}

//DATEPICKER overrdies
.dp-popup {
  background-color: var(--white90) !important;
  backdrop-filter: blur(10px) !important; // TODO should be 60px, but is completly white to me
  border: 1px solid var(--white) !important;
  border-radius: 16px;
  box-shadow: var(--card-shadow) !important;

  .dp-selected {
    border-radius: 8px;
    background: var(--blue) !important;
    color: colors.$white !important;
  }

  dp-calendar-nav {
    .dp-nav-header {
      font-size: 14px;
      font-weight: 500;
    }
    .dp-calendar-nav-left:before,
    .dp-calendar-nav-right:before {
      color: var(--dark-grey);
    }
  }

  dp-day-calendar {
    .dp-calendar-day {
      text-align: center;
    }
    .dp-calendar-weekday {
      border: unset;
    }

    .dp-weekdays {
      color: colors.$semi-grey;
    }

    .dp-next-month,
    .dp-prev-month {
      opacity: unset;
      color: var(--grey);
    }
  }

  .dp-calendar-nav-container {
    border: unset;
    margin-bottom: 10px;
  }

  .dp-day-calendar-container,
  .dp-month-calendar-container {
    background: unset;
  }
  padding: 16px;
  button {
    all: unset;
  }

  .dp-calendar-wrapper,
  dp-calendar-nav-container {
    border: unset;
  }

  .dp-months-row {
    margin-top: 12px;
  }

  .dp-calendar-month {
    margin-left: 2px;
    margin-right: 2px;
    padding: 8px;
  }

  .dp-nav-header ::after {
    color: var(--dark-grey);
    margin-left: 14px;
    position: relative;
    content: '';
    display: inline-block;
    height: 8px;
    width: 8px;
    vertical-align: baseline;
    border-style: solid;
    border-width: 2px 2px 0 0;
    transform: translate(0px, -2px) rotate(135deg);
  }
}
dp-day-time-calendar dp-time-select {
  border: unset !important;
}
dp-time-select .dp-time-select-controls {
  background: unset !important;
}

// TOOLTIP
.ng-tooltip {
  text-align: center;
  z-index: 100002;
  opacity: 0;
  background-color: var(--light-grey);
  padding: 4px 16px;
  position: absolute;
  border-radius: 20px;
  pointer-events: none;
  backdrop-filter: blur(30px);

  &.small {
    font-size: 12px;
    line-height: 16px;
  }
}

.ng-tooltip-show {
  opacity: 1;
  pointer-events: none;
}

//SWIPER
.swiper-dot {
  cursor: pointer;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  background-color: var(--grey);

  &.active {
    background-color: var(--blue);
  }
}
.swiper-center-items {
  .swiper-wrapper {
    align-items: center;
  }
}

// LIST GRADIENTS
.scroll-list-wrapper {
  &.gradient-up {
    &:before {
      @include gradient;
      top: 0;
      background: linear-gradient(to bottom, var(--white) 20%, rgba(0, 0, 0, 0) 100%);
    }
  }
  &.gradient-bottom {
    &:after {
      @include gradient;
      bottom: -1px;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 20%, var(--white) 100%);
    }
  }
}
