$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge((
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 2),
  6: ($spacer * 3)
), $spacers);

$infix: '';

@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $length in $spacers {

    .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
    .#{$abbrev}t#{$infix}-#{$size},
    .#{$abbrev}y#{$infix}-#{$size} {
      #{$prop}-top: $length !important;
    }
    .#{$abbrev}r#{$infix}-#{$size},
    .#{$abbrev}x#{$infix}-#{$size} {
      #{$prop}-right: $length !important;
    }
    .#{$abbrev}b#{$infix}-#{$size},
    .#{$abbrev}y#{$infix}-#{$size} {
      #{$prop}-bottom: $length !important;
    }
    .#{$abbrev}l#{$infix}-#{$size},
    .#{$abbrev}x#{$infix}-#{$size} {
      #{$prop}-left: $length !important;
    }
  }
}