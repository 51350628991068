@font-face {
  font-family: 'Museo Sans';
  font-weight: normal;
  src: url(../assets/fonts/MuseoSans-300.otf) format('opentype');
}

@font-face {
  font-family: 'Museo Sans';
  font-weight: 100;
  src: url(../assets/fonts/MuseoSans-100.otf) format('opentype');
}

@font-face {
  font-family: 'Museo Sans';
  font-weight: 300;
  src: url(../assets/fonts/MuseoSans-300.otf) format('opentype');
}

@font-face {
  font-family: 'Museo Sans';
  font-weight: 500;
  src: url(../assets/fonts/MuseoSans-500.otf) format('opentype');
}

@font-face {
  font-family: 'Museo Sans';
  font-weight: 700;
  src: url(../assets/fonts/MuseoSans-700.otf) format('opentype');
}
